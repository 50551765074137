* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Archivo", Arial;
}

.contact_btn {
  background-color: green;
  margin: 10px;
}

textarea,
input {
  outline: none;
  height: 50px;
  background-color: #9999ca40;
  border: 1px solid transparent;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  border-radius: 1px;
  margin-bottom: 20px;
  transition: all 0.3s linear;
  &:focus {
    background-color: #47476a40;
    border: 1px solid #9999ca40;
  }
}

input::-webkit-input-placeholder {
  color: #eaeaea !important;
}
input[type="date"] {
  color: #eaeaea !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
html {
  scroll-behavior: smooth;
}

span {
  cursor: default;
}

p {
  font-size: 20px;
  cursor: default;
}

h1 {
  font-size: 45px;
  cursor: default;
}

h2 {
  font-size: 28px;
  cursor: default;
}

h3 {
  font-size: 24px;
  cursor: default;
}

h4 {
  font-size: 20px;
  cursor: default;
}

body {
  color: #eaeaea !important;
  background: #181818;
}

a {
  color: inherit;
  text-decoration: none;
}

li,
button {
  cursor: pointer;
}

section {
  margin: 150px 400px;
}

mark {
  background-color: #66ac82;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@media only screen and (max-width: 1390px) {
  section {
    margin: 150px;
  }
}

@media only screen and (max-width: 1000px) {
  section {
    margin: 50px;
  }
}

@media only screen and (max-width: 780px) {
  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: "Archivo", Arial;
  }
  section {
    margin: 20px;
  }

  .contact_btn {
    background-color: green;
    margin: 10px;
  }

  textarea,
  input {
    outline: none;
    height: 50px;
    background-color: #9999ca8a;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    border-radius: 1px;
    margin-bottom: 20px;
  }

  input::-webkit-input-placeholder {
    opacity: 0.5;
  }

  span {
    cursor: default;
  }

  p {
    font-size: 18px;
    cursor: default;
  }

  h1 {
    font-size: 28px;
    cursor: default;
  }

  h2 {
    font-size: 24px;
    cursor: default;
  }

  h3 {
    font-size: 20px;
    cursor: default;
  }

  h4 {
    font-size: 18px;
    cursor: default;
  }
}
