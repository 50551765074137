@import "../../styles/variables.module.scss";

.main_section_header {
  z-index: 9999;
  position: fixed;
  background-color: #18181899;
  top: 0;
  display: flex;
  width: 100%;
  padding: 25px 300px;
  flex-direction: row;
  justify-content: space-between;

  .burger_menu_closed {
    display: none;
    visibility: hidden;
  }

  .change {
    .bar1 {
      transform: translate(0, 11px) rotate(-45deg);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      transform: translate(0, -11px) rotate(45deg);
    }
  }
  .main_section_header_logo {
    white-space: nowrap;
    font-family: $font-black;
    font-size: $logo-font-size;
    font-weight: bold;
    cursor: wait;
    & > span {
      color: $purple-accent;
    }
  }

  #bg {
    height: 400px;
    width: 400px;
  }

  .main_section_header_menu {
    & > ul {
      text-align: center;
      list-style: none;
      display: flex;
      & > li {
        text-transform: uppercase;
        padding: 15px;
        margin: 5px;
        transition: all 0.3s linear;
        &:last-child {
          color: black;
          border-radius: 2px;
          background-color: $mint-accent;
        }
        &:last-child:hover {
          background-color: #94dbb0;
        }
        &:hover {
          background-color: #ffffff10;
        }
      }
    }
  }
}

.main_section {
  .main_section_text_wrapper {
    margin-top: 100px;
    height: 700px;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $radial-gradient;
    .main_section_text_wrapper_container {
      height: fit-content;
      width: fit-content;
      & > h4 {
        color: black;
        margin-top: 30px;
      }
      & > h1 > span {
        color: $purple-accent;
      }
    }
  }
}

#container {
  border: 1 px solid white;
}

.main_section_image {
  visibility: hidden;
  display: none;
}

@media only screen and (max-width: 1390px) {
  .main_section_header {
    padding: 25px 150px;
  }
}

@media only screen and (max-width: 1000px) {
  .main_section_header {
    padding: 25px 50px;
  }
}

@media only screen and (max-width: 780px) {
  .main_section_header {
    background-color: #18181899;
    position: fixed;
    top: 0;
    padding: 20px;
    z-index: 9999;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .burger_menu_closed {
      display: block;
      visibility: visible;
      .bar1,
      .bar2,
      .bar3 {
        width: 35px;
        height: 5px;
        background-color: $white-text;
        margin: 6px 0;
        transition: 0.4s;
      }
    }

    .change {
      z-index: 2;
      .bar1 {
        transform: translate(0, 11px) rotate(-45deg);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        transform: translate(0, -11px) rotate(45deg);
      }
    }

    .main_section_header_logo {
      font-family: $font-black;
      font-size: 25px;
      font-weight: bold;
      cursor: wait;
      & > span {
        color: $purple-accent;
      }
    }
    .main_section_header_menu {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.9);
      overflow-x: hidden;
      transition: 0.5s;

      & > ul {
        margin-top: 20%;
        text-align: center;
        list-style: none;
        flex-direction: column;
        display: flex;
        & > li {
          text-transform: uppercase;
          padding: 15px;
          margin: 5px;
          transition: transform 0.3s ease-in-out;
          &:last-child {
            color: black;
            border-radius: 2px;
            background-color: $mint-accent;
          }
        }
      }
    }
  }

  .main_section {
    padding: 0 !important;
    .main_section_text_wrapper {
      background: $radial-gradient;
      height: 680px;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .container {
        height: 100%;
        width: 100%;
        background-color: #94dbb0;
      }
      .main_section_text_wrapper_container {
        position: absolute;
        height: fit-content;
        width: fit-content;
        & > h4 {
          margin-top: 30px;
        }
        & > h1 > span {
          color: $purple-accent;
        }
      }
    }
  }
  #container {
    overflow: hidden;
    .canvas_container {
      // height: 100%;
      // height: 100%;
      // canvas {
      height: 500px;
      width: 500px;
      // }
    }
  }
}
