.footer {
  width: 100;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  .footer_inner_wrapper {
    padding-bottom: 30px;
  }
}
