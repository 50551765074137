@import "../../styles/variables.module.scss";

.process_section {
  .process_section_header {
    margin-bottom: 50px;
    & > h1 > span {
      color: $purple-accent;
    }
  }
  .process_section_card_wrapper {
    height: 321px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    .process_card {
      transition: transform 0.3s ease-in-out;
      padding: 30px;
      background: $linear-gradient;
      p {
        color: rgb(220, 220, 220);
      }
      .process_card_number {
        font-size: 70px;
        font-family: $font-black;
        margin-bottom: 30px;
        color: white;
      }
      .process_card_button {
        border: none;
        border-radius: 1;
        appearance: none;
        width: 100%;
        text-transform: uppercase;
        padding: 15px;
        margin-top: 40px;
        color: black;
        border-radius: 1px;
        background-color: $mint-accent;
        transition: all 0.3s linear;
      }
      .process_card_button:hover {
        background-color: #94dbb0;
      }
    }
    .process_card:hover {
      transform: scale(1.05);
    }
  }
}

@media only screen and (max-width: 780px) {
  .process_section {
    .process_section_header {
      margin-bottom: 50px;
      & > h1 > span {
        color: $purple-accent;
      }
    }
    .process_section_card_wrapper {
      height: fit-content;
      display: flex;
      gap: 30px;
      flex-direction: column;
      .process_card {
        padding: 30px;
        background: $linear-gradient;
        .process_card_number {
          font-size: 70px;
          font-family: $font-black;
          margin-bottom: 30px;
        }
        .process_card_button {
          border: none;
          border-radius: 1;
          appearance: none;
          width: 100%;
          text-transform: uppercase;
          padding: 15px;
          margin-top: 40px;
          color: black;
          border-radius: 1px;
          background-color: $mint-accent;
        }
      }
    }
  }
}
