@import "../../styles/variables.module.scss";

.service_container {
  padding-bottom: 150px;
  .service_container_card_wrapper {
    padding-top: 50px;
    height: 800px;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 25px;
    grid-template-rows: 1fr 1fr;
  }
  .service_card {
    padding: 50px 50px 100px;
    background: $linear-gradient;
    transition: transform 0.3s ease-in-out;
  }
  h2{
    color: rgb(255, 255, 255);
  }
  p{
    color: rgb(220, 220, 220);
  }
  .service_card:hover {
    transform: scale(1.05);
  }
  .card_1 {
    grid-row: 1 / 1;
    grid-column: 1 / 7;
  }
  .card_2 {
    grid-row: 1 / 1;
    grid-column: 7 / 12;
  }
  .card_3 {
    grid-row: 2 / 2;
    grid-column: 6 / 12;
  }
  .card_4 {
    grid-row: 2 / 2;
    grid-column: 1 / 6;
  }
}

.card_header {
  white-space: pre-wrap;
  margin-bottom: 20px;
}

@media only screen and (max-width: 780px) {
  .service_container {
    padding-bottom: 150px;
    .service_container_card_wrapper {
      padding-top: 50px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      // grid-template-columns: repeat(11, 1fr);
      gap: 10pxpx;
      // grid-template-rows: 1fr 1fr;
    }
    .service_card {
      padding: 50px 50px 100px;
      background: $linear-gradient;
    }
  }
}
