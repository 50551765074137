@import "../../styles/variables.module.scss";

.section_about {
  padding-bottom: 100px;
  .section_about_container {
    display: flex;
    flex-direction: column;
    .section_about_container_header {
      margin: 150px auto 10px;
      & > h1 {
        color: white;
      }
      & > h1 > span {
        color: $purple-accent;
      }
    }
    .section_about_container_content_wrapper {
      height: 500px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .section_about_container_content_wrapper_img_wrapper {
        position: relative;
      }
      .section_about_container_content_wrapper_text_wrapper {
        align-self: center;
        font-size: 20px;
        color: rgb(220, 220, 220);
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .section_about {
    padding-bottom: 150px;
    .section_about_container {
      display: flex;
      flex-direction: column;
      .section_about_container_header {
        margin: 150px auto 50px;
        & > h1 > span {
          color: $purple-accent;
        }
      }
      .section_about_container_content_wrapper {
        height: fit-content;
        display: flex;
        .section_about_container_content_wrapper_text_wrapper {
          align-self: center;
          font-size: 20px;
        }
      }
    }
  }

  img {
    visibility: hidden;
    display: none;
  }
}
