$mint-accent: #66ac82;
$primary-background: #181818;
$purple-accent: #6755b2;
$white-text: #eaeaea;
$linear-gradient: linear-gradient(120deg, #6654af80, #181818);
$radial-gradient: radial-gradient(
  circle at 65%,
  #0016e040 3%,
  #2647cd4c 15%,
  transparent 30%
);
$radial-gradient-hover: radial-gradient(
  #9eaee36e,
  transparent 30%
);



$font-black: "Archivo Black", sans-serif;
$font-primary: "Archivo", sans-serif;
$h1: 45px;
$h2: 28px;
$h2: 24px;
$h3: 20px;
$logo-font-size: 32px;
