@import "../../styles/variables.module.scss";

.form {
  background: $linear-gradient;
  .form_container {
    padding: 70px;
    .form_container_header {
      margin-bottom: 50px;
    }
    .form_container_actual_form {
      display: grid;
      grid-template-rows: 1fr 1fr 1.1fr 1fr;
      grid-template-columns: 1fr 1fr;
      & div:nth-child(5) {
        grid-column: 1/3;
        margin-top: 10px;
        & > textarea {
          margin-top: 20px;
          padding: 20px;
          width: 80%;
          height: 80%;
        }
      }
      & div:nth-child(6) {
        grid-column: 1/3;
      }
      .form_container_actual_form_budget_input {
        & > h4 {
          margin-bottom: 20px;
        }
      }
      .form_container_actual_form_input_start_date {
        & > h4 {
          margin-bottom: 20px;
        }
      }
      .form_container_actual_form_input_checkbox {
        display: flex;
        align-items: center;
        text-align: start;
        margin: 20px 0 10px;
        justify-content: left;

        & > input {
          margin-bottom: 0 !important;
          transform: scale(1.5);
        }
        span {
          padding: 20px;
          color: $mint-accent;
        }
      }
      .form_submit_button {
        border: none;
        border-radius: 1;
        appearance: none;
        min-width: 30%;
        text-transform: uppercase;
        padding: 15px;
        margin-top: 25px;
        color: black;
        border-radius: 1px;
        background-color: $mint-accent;
        transition: all 0.3s linear;
      }
      .form_submit_button:hover {
        background-color: #94dbb0;
      }
    }
  }
}

h1 {
  color: white;
}

#message_input {
  width: 100% !important;
  background-color: beige;
}

@media only screen and (max-width: 780px) {
  .form {
    background: $linear-gradient;
    .form_container {
      padding: 35px 20px;
      text-align: center;
      .form_container_header {
        margin-bottom: 50px;
      }
      .form_container_actual_form {
        display: flex;
        flex-direction: column;
        & div:nth-child(6) {
          grid-column: 1/3;
        }
        .form_container_actual_form_budget_input {
          & > h4 {
            margin-bottom: 20px;
          }
        }
        .form_container_actual_form_input_start_date {
          & > h4 {
            margin-bottom: 20px;
          }
        }
        .form_container_actual_form_input_checkbox {
          padding: 20px !important;
          display: flex;
          align-items: center;
          text-align: start;
          margin-left: auto;
          justify-content: left;
          color: $mint-accent;
          & > input {
            margin: 5px;
            height: 15px;
          }
        }
        .form_submit_button {
          border: none;
          border-radius: 1;
          appearance: none;
          min-width: 30%;
          text-transform: uppercase;
          padding: 15px;
          margin-top: 40px;
          color: black;
          border-radius: 1px;
          background-color: $mint-accent;
        }
        .form_submit_button:hover {
          background-color: #94dbb0;
        }
      }
    }
  }

  #message_input {
    width: 100% !important;
    background-color: beige;
  }
}
